import { InputStatus, makeMonth } from '@/ghgApi'
import { SubmissionStatus } from '@/openapi'
import { getTime } from './nendo'

type TScope = { id: number; status: InputStatus | SubmissionStatus | null }

export type RowProps = {
    date: {
        year: number
        month: number
    }
    dateString: string
    status: InputStatus | SubmissionStatus | null
    scope1?: TScope
    scope2?: TScope
    scope3_1?: TScope
    scope3_2?: TScope
}

const genStatus = (year: number, month: number) => {
    const { year: currYear, month: currMonth } = getTime()
    if (year < currYear) return null
    else if (year === currYear) {
        if (month <= currMonth) return null
        return InputStatus.INACITVE
    } else {
        return InputStatus.INACITVE
    }
}

export const makeRows = (year: number, startMonth: number): Array<RowProps> => {
    const rows = new Array<RowProps>()
    for (let i = startMonth; i < 12 + startMonth; i++) {
        if (i > 12) {
            const yearResult = year + 1
            const month = i - 12
            rows.push({
                date: { year: yearResult, month },
                dateString: makeMonth(yearResult, month),
                status: genStatus(yearResult, month),
            })
        } else {
            const yearResult = year
            const month = i
            rows.push({
                date: { year: yearResult, month },
                dateString: makeMonth(yearResult, month),
                status: genStatus(yearResult, month),
            })
        }
    }

    return rows
}
