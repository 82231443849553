import { CustomTooltip } from '@/components/atoms/CustomTooltip'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import { InputStatus } from '@/ghgApi'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import { RowProps } from '@/utils/inputHelper'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { navigate } from 'gatsby'
import React from 'react'

const listHeader = [
    { id: 1, text: '月' },
    { id: 2, text: 'Scope1' },
    { id: 3, text: 'Scope2' },
    { id: 4, text: 'Scope3（上流：カテゴリ1〜8）' },
    { id: 5, text: 'Scope3（下流：カテゴリ9〜15）' },
]

const useStyte = makeStyles({
    tableContainer: {
        backgroundColor: theme.colors.white,
    },
    tableRowHead: {
        '& > th': {
            fontWeight: 600,
        },
        backgroundColor: theme.colors.lightGray,
    },
    tableRowBody: {
        '&:nth-child(even)': {
            backgroundColor: theme.colors.lightGray,
        },
    },
    tableCell: {
        fontSize: 14,
        minWidth: 200,
        textAlign: 'center',
        '&:not(:last-child)': {
            borderRight: `1px dashed ${theme.colors.borderColor}`,
        },
    },
    statusText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    error: {
        color: theme.colors.errorN,
    },
    success: {
        color: theme.colors.primaryN,
    },
    errorWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 5,
    },
    // btn disabled scope 3
    disabledBtn: {
        color: ' #42526E',
    },
})

function MonthTable(props: { data: RowProps[]; fiscalYear: number }) {
    const classes = useStyte()
    const {
        disabledScope3,
        analysisState,
        inputScopeState,
        setInputScopeState,
        setSelectedCategory,
        setAnalysisState,
    } = useStore()
    const [, setDateSessionStorage] = useStateWithSessionStorage<InputDate | null>(
        SessionStorageKey.DATA_INPUT_DATE,
        null,
    )

    const handleCellClick = (id?: number, date?: InputDate, scope3Id?: number) => {
        if ((id || id === 0) && date) {
            setDateSessionStorage(date)
            setAnalysisState({ ...analysisState, date: { ...analysisState.date, year: props.fiscalYear } })
            setInputScopeState({ ...inputScopeState, date, tabValue: id })
            if (scope3Id === 1) setSelectedCategory(3)
            if (scope3Id === 2) setSelectedCategory(11)
            navigate(ROUTES.INPUT_SCOPES)
        }
    }

    const generateStatusInput = (
        status?: string | null,
        id?: number,
        date?: InputDate,
        scop3Id?: number,
        disabled?: boolean,
    ) => {
        if (status === InputStatus.INACITVE) return <span>-</span>
        if (disabled) return <Disabled />
        if (status === InputStatus.DONE)
            return (
                <span
                    className={`${classes.statusText} ${classes.success}`}
                    onClick={() => handleCellClick(id, date, scop3Id)}
                >
                    入力完了
                </span>
            )
        else
            return (
                <div className={`${classes.errorWrapper}`} onClick={() => handleCellClick(id, date, scop3Id)}>
                    <span className={`${classes.statusText} ${classes.error}`}>未入力</span>
                    <ErrorIcon />
                </div>
            )
    }

    const Disabled = () => (
        <CustomTooltip
            title="現在、ご契約中のプランは「スターター」です。 Scope3を利用するには「ベーシック」または「エンタープライズ」へのアップグレードが必要です。管理者の方は管理画面のプラン情報からお問い合わせください。"
            width={400}
        >
            <div className={classes.disabledBtn}>入力不可</div>
        </CustomTooltip>
    )

    return (
        <TableContainer>
            <Table className={classes.tableContainer}>
                <TableHead>
                    <TableRow className={classes.tableRowHead}>
                        {listHeader.map((item) => (
                            <TableCell key={item.id} className={classes.tableCell}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((item) => (
                        <TableRow className={classes.tableRowBody} key={item.date.month}>
                            <TableCell className={classes.tableCell}>{item.date.month}月</TableCell>
                            <TableCell className={classes.tableCell}>
                                {generateStatusInput(item.scope1?.status, item.scope1?.id, item.date)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {generateStatusInput(item.scope2?.status, item.scope2?.id, item.date)}
                            </TableCell>
                            <TableCell className={`${classes.tableCell}`}>
                                {generateStatusInput(
                                    item.scope3_1?.status,
                                    item.scope3_1?.id,
                                    item.date,
                                    1,
                                    disabledScope3,
                                )}
                            </TableCell>
                            <TableCell className={`${classes.tableCell}`}>
                                {generateStatusInput(
                                    item.scope3_2?.status,
                                    item.scope3_2?.id,
                                    item.date,
                                    2,
                                    disabledScope3,
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MonthTable
